section.hero {
  /* width: 100vw; */
  height: calc(100vh - 5vh);
  padding-bottom: 5vh;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  text-transform: uppercase;
}

.text {
  position: relative;
  display: flex;
  flex-flow: column wrap;
}

.line.first_line {
  text-align: start;
}

.line span {
  display: inline-flex;
  position: relative;
  overflow: hidden;
  -webkit-box-align: baseline;
  align-items: baseline;
  white-space: nowrap;
}

.hero .text .line {
  text-transform: uppercase;
  width: auto;
  white-space: nowrap;
  letter-spacing: 0.03em;
  color: rgb(255, 255, 255);
  font-size: 115px;
  line-height: 0.9;
}

.hero .text .line.second_line {
  padding-left: 240px;
}

.hero .text .line.third_line {
  position: relative;
  padding-left: 150px;
}

.line.second_line .and {
  border: 1px solid;
  border-radius: 50%;
  display: inline-block;
  font-size: 16px;
  transform: translate3d(-10px, 20px, 0);
  padding: 5px 10px;
}

.hero .text .line.third_line::after {
  position: absolute;
  content: "Phuoc is an engineer based in Toronto.";
  width: 250px;
  top: -60px;
  right: -70px;
  font-size: 12px;
  text-transform: uppercase;
  white-space: initial;
  color: rgb(255, 255, 255);
  line-height: 1.4;
  letter-spacing: 0.01em;
}

/* @media (max-width: 480px) {
  .hero {
    padding: 0px 28px;
  }
} */

.hero .scroll-indicator {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
}

/*scroll indicator*/

.scroll-indicator {
  width: 12px;
  margin: 0 auto;
  position: relative;
  opacity: 0.5;
}
.scroll-indicator::before,
.scroll-indicator::after {
  content: "";
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.scroll-indicator::before {
  width: 12px;
  height: 12px;
  border-radius: 10px;
  border: 1px solid #fff;
  -webkit-animation: dot 3s infinite ease-in-out;
  animation: dot 3s infinite ease-in-out;
}
.scroll-indicator::after {
  width: 7px;
  height: 7px;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform: rotate(45deg);
  -webkit-animation: arrow 3s infinite ease-in-out;
  animation: arrow 3s infinite ease-in-out;
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
  opacity: 0.25;
}
.scroll-indicator > div {
  display: block;
}
.scroll-indicator > div::before,
.scroll-indicator > div::after {
  content: "";
  display: block;
  margin: 5px auto;
  border-radius: 10px;
  border: 1px solid #fff;
  -webkit-animation: dot 3s infinite ease-in-out;
  animation: dot 3s infinite ease-in-out;
}
.scroll-indicator > div::before {
  width: 8px;
  height: 8px;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}
.scroll-indicator > div::after {
  width: 6px;
  height: 6px;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

#tsparticles {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@-webkit-keyframes dot {
  0% {
    transform: scale(0.75);
    opacity: 0.25;
  }
  25% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.75);
    opacity: 0.25;
  }
}

@keyframes dot {
  0% {
    transform: scale(0.75);
    opacity: 0.25;
  }
  25% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.75);
    opacity: 0.25;
  }
}
@-webkit-keyframes arrow {
  0% {
    transform: scale(0.75) rotate(45deg);
    opacity: 0.25;
  }
  25% {
    transform: scale(1) rotate(45deg);
    opacity: 1;
  }
  100% {
    transform: scale(0.75) rotate(45deg);
    opacity: 0.25;
  }
}
@keyframes arrow {
  0% {
    transform: scale(0.75) rotate(45deg);
    opacity: 0.25;
  }
  25% {
    transform: scale(1) rotate(45deg);
    opacity: 1;
  }
  100% {
    transform: scale(0.75) rotate(45deg);
    opacity: 0.25;
  }
}

/* Mobile */
@media only screen and (max-width: 480px) {
  .hero .text .line {
    font-size: 2em !important;
  }
  .hero .text .line.second_line {
    padding-left: 0px;
  }

  .hero .text {
    margin-top: 0vh !important;
  }

  .hero .text .line.third_line {
    position: relative;
    padding-left: 0px;
  }

  .line.second_line .and {
    border: 1px solid;
    border-radius: 50%;
    display: inline-block;
    font-size: 16px;
    transform: translate3d(0);
    padding: 5px 10px;
  }
  .hero .text .line.third_line::after {
    content: "" !important;
  }

  .line.second_line .and {
    border: 1px solid;
    border-radius: 50%;
    display: inline-block;
    font-size: 16px;
    transform: translate3d(0px, 0px, 0);
    padding: 5px 10px;
  }
}

@media (max-width: 780px) {
  .hero .text {
    margin-top: -25vh;
  }

  .hero .text .line {
    font-size: 40px;
  }
}

@media (max-width: 1200px) {
  .hero .text .line {
    font-size: 80px;
  }
  .hero .text .line.third_line::after {
    position: absolute;
    content: "Phuoc is an engineer based in Toronto.";
    width: 250px;
    top: -60px;
    right: -100px;
    font-size: 12px;
    text-transform: uppercase;
    white-space: initial;
    color: rgb(255, 255, 255);
    line-height: 1.4;
    letter-spacing: 0.01em;
  }
}
