.footer_section {
  margin: 0px auto;
  width: 80vw;
  display: flex;
  flex-flow: column nowrap;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 20vh;
  padding-bottom: 10vh;
}

.small-line {
  margin-top: -20px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.small-line .socials {
  display: flex;
  flex-flow: row wrap;
}

ol.socials,
ul.socials {
  list-style: none;
}

.small-line .socials li {
  text-transform: uppercase;
  font-size: 14px;
  margin: 0px 20px;
}

@media (max-width: 750px) {
  .small-line .socials {
    -webkit-box-pack: center;
    justify-content: center;
  }
}
