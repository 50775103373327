.project_section {
  padding: 0px 10vw;
  /* display: flex; */
  /* flex-flow: column nowrap; */
  align-items: center;
  margin-top: 20vh;
}

.projects_container {
  width: 100%;
}

.project_content {
  display: flex;
  margin-bottom: 40px;
  transform: none;
}

.project_content .title {
  margin-left: 40px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: normal;
  font-size: 100px;
  color: rgb(255, 255, 255);
  line-height: 0.9;
  letter-spacing: 0.03em;
}

.hover-images-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10;
  pointer-events: none;
}

.hover-images-wrapper .hover-images {
  transition: opacity 0.3s ease-out 0s;
}

.hover-images-wrapper .hover-images img,
.hover-images-wrapper .hover-images video {
  width: 35vw;
  height: auto;
  object-fit: cover;
}

.index {
  color: rgb(255, 255, 255);
}

.headline {
  color: white;
}

/*-----------*/

.container {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  /* height: 100vh;
  width: 100vw; */
  /* background: #333; */
}

h1 {
  text-align: center;
  text-transform: uppercase;
  /* font-family: Helvetica, Arial, sans-serif; */
  font-size: 50px;
  color: #fff;
  margin: 0 auto;
}

.animation {
  position: relative;
  width: 100%;
}
.animation div {
  overflow: hidden;
  width: 50%;
  height: 100%;
  transform: skewX(-20deg);
}
.animation div::before {
  content: "";
  position: absolute;
  width: 0%;
  height: 4px;
  background: #fff;
}
.animation span {
  padding: 0 10px;
}

.animation-left::before {
  left: 0;
  animation: widthLineLeft 1.2s 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.1)
    forwards;
}
.animation-left span {
  display: block;
  animation: slideLeft 1.2s 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.1) forwards;
  transform: translateX(200px) skewX(20deg);
}

.animation-right {
  position: absolute;
  right: 0;
  top: 0;
}
.animation-right:before {
  bottom: 0;
  right: 0;
  animation: widthLineRight 1.2s 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.1)
    forwards;
}
.animation-right span {
  position: absolute;
  width: 100%;
  left: -100%;
  transform: translateX(-200px) skewX(20deg);
  animation: slideRight 1.2s 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.1) forwards;
}

.invisble {
  visibility: hidden !important;
}

@keyframes slideLeft {
  from {
    transform: translateX(200px) skewX(20deg);
  }
  to {
    transform: translateX(0%) skewX(20deg);
  }
}
@keyframes slideRight {
  from {
    transform: translateX(-200px) skewX(20deg);
  }
  to {
    transform: translateX(0%) skewX(20deg);
  }
}
@keyframes widthLineLeft {
  0% {
    width: 0%;
    left: 0%;
  }
  50% {
    width: 100%;
    left: 0%;
  }
  100% {
    width: 0%;
    left: 100%;
  }
}
@keyframes widthLineRight {
  0% {
    width: 0%;
    right: 0%;
  }
  50% {
    width: 100%;
    right: 0%;
  }
  100% {
    width: 0%;
    right: 100%;
  }
}

@media screen and (max-width: 800px) {
  h1 {
    font-size: 30px;
  }
}

@media (max-width: 480px) {
  .project_content .title {
    margin-left: 18px;
    font-size: 40px;
    line-height: 1.1;
  }
}
