.site__nav.show {
  opacity: 1;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.site__nav {
  left: 0;
  mix-blend-mode: difference;
  opacity: 0;
  position: fixed;
  top: 60px;
  transition: all 0.5s cubic-bezier(0.55, 0.28, 0.49, 0.74);
  width: 100%;
  z-index: 999;
}

.site__navbar {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  /* padding: 24px 8px; */
  align-items: center;
  flex-flow: row nowrap;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0px 5vw;
}

/* @media (min-width: 992px) {
  .site__navbar {
    padding: 0px 5vw;
  }
} */

.site__navbar-link {
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.site__navbar-link--tl {
  position: relative;
}

svg {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

svg g path {
  fill: rgb(0, 153, 255);
}

.menu-toggle {
  width: 40px;
  height: 40px;
  pointer-events: auto;
  color: rgb(255, 255, 255);
}

.menu-toggle .top,
.menu-toggle .bottom {
  width: 100%;
  height: 2px;
  background: rgb(255, 255, 255);
}

.menu-toggle:hover .bottom {
  width: 80%;
}

.menu-toggle .bottom {
  width: 50%;
  margin-top: 8px;
  transition: all 0.3s ease-in-out 0s;
}
