.skills {
  margin-top: 20vh;
  color: white;
}

.skills_section {
  padding: 0px 10vw;
  /* display: flex; */
  /* flex-flow: column nowrap; */
  -webkit-box-align: center;
  align-items: center;
}

.skills_section .skills_headline {
  text-transform: uppercase;
  font-size: 14px;
  justify-content: center;
  text-align: center;
}

.separator.text-top {
  margin-bottom: 40px;
}

/* .line {
  -webkit-box-pack: end;
  justify-content: flex-end;
  flex-flow: row nowrap;
  display: inline-flex;
} */

.m-scroll {
  display: flex;
  position: relative;
  width: 100%;
  height: 200px;
  margin: auto;
  /* background-color: #d34444; */
  overflow: hidden;
  z-index: 1;
}
.m-scroll__title {
  display: flex;
  position: absolute;
  top: 0;
  left: -4500px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  transform: scale(2);
  transition: all 1s ease;
}
.m-scroll__title > div {
  display: flex;
  -webkit-animation: scrollText 33s infinite linear;
  animation: scrollText 33s infinite linear;
}
.m-scroll__title h1 {
  margin: 0;
  font-size: 140px;
  color: #ffffff;
  transition: all 2s ease;
}
.m-scroll__title a {
  text-decoration: none;
  color: white;
}
.m-scroll__title a:hover {
  -webkit-text-stroke: 1px white;
  color: transparent;
}
/*div:hover {
    animation-play-state: paused;
  }*/
@-webkit-keyframes scrollText {
  from {
    transform: translateX(-10%);
  }
  to {
    transform: translateX(50%);
  }
}
@keyframes scrollText {
  from {
    transform: translateX(-10%);
  }
  to {
    transform: translateX(50%);
  }
}

.m-scroll__title_left {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  transform: scale(2);
  transition: all 1s ease;
}
.m-scroll__title_left > div {
  display: flex;
  -webkit-animation: scrollText_left 33s infinite linear;
  animation: scrollText_left 33s infinite linear;
}
.m-scroll__title_left h1 {
  margin: 0;
  font-size: 140px;
  color: #ffffff;
  color: transparent;
  -webkit-text-stroke: 1px rgb(255, 255, 255);
  transition: all 2s ease;
}
.m-scroll__title_left a {
  text-decoration: none;
  color: rgb(255, 255, 255) !important;
  color: transparent !important;
}
.m-scroll__title_left a:hover {
  color: rgb(255, 255, 255) !important;
}
/*div:hover {
      animation-play-state: paused;
    }*/
@-webkit-keyframes scrollText_left {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}
@keyframes scrollText_left {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

@media only screen and (max-width: 480px) {
  .m-scroll__title_left h1 {
    font-size: 50px;
  }

  .m-scroll__title {
    display: flex;
    position: absolute;
    top: 0;
    left: -2000px;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    transform: scale(2);
    transition: all 1s ease;
  }
  .m-scroll__title h1 {
    font-size: 50px;
  }

  .m-scroll {
    height: 70px;
  }
}
