a {
  color: #000;
  cursor: url("../public/cursor.svg") 14 16, auto;
}

a {
  background-color: transparent;
}

/* DARK MODE */
.App.dark-mode a,
.App.dark-mode button {
  color: #fff;
  cursor: url("../public/cursor_darkmode.svg") 14 16, auto;
}

.App.dark-mode {
  color: white;
}

button {
  border: none;
  margin: 0px;
  padding: 0px;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  appearance: none;
}
