.about_wrapper {
  color: white;

  margin-top: 30vh;
}

.section {
  padding: 0px 10vw;
  /* width: 100vw; */
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.title {
  text-align: center;
}

.text-h2-5 {
  text-transform: uppercase;

  font-size: 35px;
  line-height: 1.1;
  color: rgb(255, 255, 255);
}

.headline {
  text-transform: uppercase;
  font-size: 14px;
}

.separator {
  margin-top: 20px;
  margin-bottom: 50px;
  width: 100%;
  height: 2px;
  background-color: rgb(255, 255, 255);
  opacity: 0.2;
}

.button {
  border: 1px solid rgb(64, 64, 64);
  padding: 15px 50px;
  border-radius: 30px;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 40px;
  transition: background-color 0.5s ease 0s;
}

.button:hover {
  background-color: rgb(0, 153, 255);
  color: rgb(255, 255, 255);
}

a {
  text-decoration: none;
}

@media (max-width: 480px) {
  .text-h2-5 {
    font-size: 24px;
    line-height: 1.2;
  }
}
