@font-face {
  font-family: Gallient;
  src: url(../public/GallientRegular-eZoMp.ttf);
}
body {
  margin: 0;
  /* min-height: 100%; */
  /* background-image: url(https://grainy-gradients.vercel.app/noise.svg); */
  background-color: rgb(24, 22, 24);

  font-family: Gallient;
  color: #0a0a0a;
  font-size: 1.15vw;
  line-height: 1.4;
  font-weight: 400;
  cursor: auto;
  margin: 0px;
  padding: 0px;
  border: 0px;
  font: inherit;
  vertical-align: baseline;
  overflow-x: hidden;
  transition: color 0.3s ease 0s;

  /*Hide scrollbar but still being able to scroll*/
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

/*Hide scrollbar but still being able to scroll*/
body::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
