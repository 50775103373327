.contact_section {
  padding: 0px 10vw;
  /* display: flex; */
  /* flex-flow: column nowrap; */
  align-items: center;
}

.contact_title {
  margin-top: 160px;
  margin-bottom: 80px;
}

.home__title--3 {
  font-size: 16vw;
  margin: var(--marginTop) 0 var(--marginTopSmall) 0;
}

.home__title,
.home__title--d {
  position: relative;
}

.home__title {
  font-size: 13vw;
  letter-spacing: 4px;
  margin: 0;
  text-align: left;
  text-transform: uppercase;
  z-index: 10;
}

.home__title,
.title--big {
  color: #363636;
  font-family: Gallient;
  font-size: 14.5vw;
  font-weight: 400;
  line-height: 1;
}

.flex-column {
  flex-direction: column !important;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(var(--bs-gutter-x) * -0.5);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-top: calc(var(--bs-gutter-y) * -1);
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.home__contact {
  margin: 0;
  padding: 0 4px;
  text-transform: uppercase;
  color: white;
}

.justify-content-center {
  justify-content: center !important;
}

.home__contact i,
.text i {
  display: inline-block;
  font-style: normal;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
}

.home__contact i:before,
.text i:before {
  background: #09f;
  color: rgb(204, 204, 204);
  content: "";
  height: calc(100% - 4px);
  left: 0;
  position: absolute;
  top: 2px;
  width: 100%;
  z-index: -1;
}

.home__contact-email {
  display: inline-block;
}

.home__contact-email u a:hover {
  color: #09f;
}

@media (min-width: 992px) {
  .home__title--3 {
    font-size: 14.5vw;
  }

  /* .home__cwrap {
    max-width: 800px;
  } */

  .home__contact {
    padding: 0;
  }

  .home__contact,
  .text--big {
    font-size: 32px;
    letter-spacing: -1px;
    line-height: 48px;
  }
}

@media (min-width: 992px) {
  .home__contact u:before,
  .text u:before {
    bottom: 0;
    height: 2px;
  }
}

@media (min-width: 576px) {
  /* .home__cwrap {
    max-width: 540px;
  } */
}
